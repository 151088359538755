import React, { useRef, useEffect } from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import get from 'lodash/get'

import factorySplash from '../home/index'

import Layout from '../components/layout'

import './home.css'

import { images } from '../images.js'

const splashImages = images.map(i => 'assets/frames/' + i)

const { start, onClick, onWindowResize, stopSound, setImages } = factorySplash(
  splashImages
)

const IndexPage = ({ pageResources }) => {
  const canvasRef = useRef()

  const data = useStaticQuery(graphql`
    query allPainting {
      allSanityPaintings(sort: { fields: [year, sorting], order: DESC }) {
        edges {
          node {
            id
            mainImage {
              _type
              asset {
                fixed(width: 512) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const images = get(data, 'allSanityPaintings.edges', []).map(({ node }) => {
    // return get(node, 'mainImage.asset.fluid.src', '')
    return get(node, 'mainImage.asset.fixed.src', '')
  })

  useEffect(() => {
    setImages(images)
    start(canvasRef.current)
    window.addEventListener('resize', onWindowResize)
    return () => {
      stopSound()
      window.removeEventListener('resize', onWindowResize)
    }
  }, [canvasRef])

  useEffect(() => {
    setImages(images)
  }, [images])

  return (
    <>
      <Layout pageResources={pageResources}>
        <div className="splash-canvas" onClick={onClick} ref={canvasRef} />
      </Layout>
    </>
  )
}

export default IndexPage
